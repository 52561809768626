.rules-section {
  padding: 2rem;
  text-align: left;
  max-width: 714px;
  margin: 0 auto;

  h2 {
    color: var(--sh-violet);
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .rules-content {
    p {
      color: #333;
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
  }
}

@media (max-width: 768px) {
  .rules-section {
    padding: 1.5rem;
  }
}
