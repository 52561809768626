.redemption-code-sub-footer {
    background-color: var(--sh-purple);
    height: 10px;
    width: 100%;
  }
  .redemption-code-footer {
    background-color: var(--sh-violet);
    padding-top: 30px;
    width: 100%;
    height: 225px;
  
    .footer-line {
      display: inline-flex;
      color: var(--sh-lighter);
      border: 1px solid #b1b1d0;
      width: 90%;
      opacity: 0.5;
      margin-bottom: 25px;
    }
  
    .redemption-code-footer-logo {
      text-align: left;
      padding-left: 50px;
      padding-bottom: 35px;
    }
  
    .redemption-code-footer-social {
      text-align: right;
      padding-right: 50px;
      img {
        margin: 6px;
      }
    }
  
    .footer-address {
      font-family: "Roboto", sans-serif;
      color: var(--sh-lighter);
      font-size: 12px;
      font-weight: 300;
      line-height: 20px;
      text-align: left;
      padding-left: 35px;
  
      .footer-links {
        a {
          color: var(--sh-lighter);
        }
      }
    }
  }
.bottom-banner img {
  width: 100%;
  height: auto;
  object-fit: contain;
}
  