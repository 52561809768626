.redemption-code-card {
  width: 65%;
  border-radius: 15px;
  .redemption-code-button {
    margin-top: 10px;
    width: 75%;
    border-radius: 30px;
    box-shadow: 5px 5px var(--sh-lighter);
    border-color: var(--sh-psy-purple);
    background-color: #ff00ff;
    a {
      color: var(--sh-lighter);
      text-decoration: none;
    }
  }
}
