.redemption-code-header {
  background-color: var(--sh-violet);
  width: 100%;
  height: 200px;
  text-align: center;
  img {
    width: auto;
    height: auto;
    margin-top: 25px;
  }
}
