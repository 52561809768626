.redemption-page-container {
  padding: 0 5px;
}

.redemption-code-content {
  margin-top: 35px;
  margin-bottom: 50px;

  .card-body {
    padding-top: 15px;
    padding-bottom: 35px;
    text-align: left;
  }
  .redemption-code-card {
    box-shadow: 5px 5px var(--sh-lighter);
    padding-left: 0;
    padding-right: 0;

    .card-footer {
      padding: 10px;
      font: normal normal 800 14px Roboto;
      background-color: var(--sh-light);
      border-radius: 0 0 12px 12px;
    }
  }

  h2 {
    font: normal normal bold 26px Roboto;
  }
}

.redemption-code-container {
  margin: 20px auto;
  font: normal normal normal 18px Roboto;
  width: 100%;
  max-width: 714px;
  border-radius: 17px;
  text-align: center;
  padding: 10px 0;
  margin-bottom: 135px;
}

.parabens-header img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.voucher-image-redemption-code {
  img {
    width: 150px;
  }
}

.voucher-name {
  font: normal normal 800 20px Roboto;
  margin-bottom: 11px;
  padding: 0 10px;
}

.voucher-price {
  font: normal normal normal 16px Roboto;
  margin-bottom: 11px;
  padding: 0 10px;
}
